<template>
    <div>
        <div class="mp2-bg pb-5">
            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-12 text-center pt-5">
                        <h2>{{ $t('message.contacts.title') }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pt-5 pb-5 contacts">
            <div class="row">
                <div class="col-12 col-md-4">
                    <h5 class="pb-3">
                        {{ $t('message.contacts.title2') }}
                    </h5>
                    <p><i class="fas fa-map-marker-alt"></i> Rua Paulo VI, 2410-146, Leiria</p>
                </div>
                <div class="col-12 col-md-4">
                    <h5 class="pb-3">
                        {{ $t('message.contacts.title3') }}
                    </h5>
                    <p>
                        <i class="fas fa-phone"></i> {{ $t('message.contacts.title5') }}: <a
                            href="tel:+351 918 925 882">+351
                        918 925 882</a>
                    </p>
                    <p>
                        <i class="far fa-envelope"></i> Email: <a href="mailto:reservas.k2go@gmail.com"></a>reservas.k2go@gmail.com
                    </p>
                </div>
                <div class="col-12 col-md-4">
                    <h5 class="pb-3">
                        {{ $t('message.contacts.title4') }}
                    </h5>
                    <p>
<i class="far fa-clock"></i> {{ $t('message.contacts.title6') }}: 10:00 – 13:00 | 14:30 – 19:30
                    </p>
                    <p><i class="far fa-clock"></i> {{ $t('message.contacts.title7') }}: 10:00 – 13:00</p>
                </div>
            </div>
        </div>

        <div class="mp-location-bg pt-5 pb-5">
            <Location></Location>
        </div>

        <form @submit.prevent="submit">
            <div class="container contacts">
                <div class="row">
                    <div class="col-12 pt-5 pb-5">
                        <h2>{{ $t('message.contacts.title8') }}</h2>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="message" :class="{ 'hasError': $v.form.message.$error }">{{
                                        $t('message.contacts.label1') }}<span
                                                style="color:red;">*</span></label>
                                    <textarea v-model="form.message" class="form-control" name="message" id="message"
                                              rows="9"></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="name" :class="{ 'hasError': $v.form.name.$error }">{{
                                        $t('message.contacts.label2') }}<span
                                                style="color:red;">*</span></label>
                                    <input type="text" v-model="form.name" class="form-control" id="name" name="name">
                                </div>
                                <div class="form-group">
                                    <label for="email" :class="{ 'hasError': $v.form.email.$error }">{{
                                        $t('message.contacts.label3') }}<span
                                                style="color:red;">*</span></label>
                                    <input type="email" v-model="form.email" id="email" class="form-control"
                                           name="email">
                                </div>

                                <p>* {{ $t('message.contacts.required') }}</p>

                                <button class="btn btn-primary">
                                    {{ $t('message.contacts.button') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import axios             from 'axios';
    import {email, required} from 'vuelidate/lib/validators';
    import json              from '../../website-data.json';
    import Location          from '../components/Location';
    import Swal              from 'sweetalert2';

    export default {
        name       : 'ServicesPage',
        components : {
            Location
        },
        data       : function () {
            return {
                json : json,
                lang : this.$route.params.lang,
                form : {
                    name    : '',
                    email   : '',
                    message : '',
                }
            };
        },
        computed   : {
            rules() {
                return {
                    name    : { required },
                    email   : { required, email },
                    message : { required }
                };
            },
        },
        methods    : {
            submit(e) {
                e.preventDefault();
                this.$v.form.$touch();
                if (this.$v.form.$error) {
                    return;
                }
                let vm = this;

                axios.post(`${process.env.VUE_APP_RESERVATION_URL}/contact`, vm.form, {
                        headers : {
                            'Content-Type'                : 'application/json',
                            'Access-Control-Allow-Origin' : '*',
                            'Accept'                      : 'application/json',
                        }
                    })
                    .then(function (response) {
                        let text = vm.lang === 'pt' ? 'Pedido de contacto enviado com sucesso!' : 'Message sent successfully!';
                        Swal.fire({
                            text              : text,
                            type              : 'success',
                            confirmButtonText : '',
                            showConfirmButton : false,
                            allowOutsideClick : false,
                            timer             : 5000,
                            icon              : 'success'
                        });

                        e.target.reset();
                    })
                    .catch(function (error) {
                        vm.errors      = ['Error. Something happened.'];
                        vm.loadSpinner = false;
                        vm.disabled    = false;
                    });
            }
        },
        validations() {
            return {
                form : this.rules
            };
        },
    };
</script>
